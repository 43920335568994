import React, { useEffect } from "react";
import ProductsList from "../components/ProductsList";
import SiteHeader from "../components/header/SiteHeader";

function MyComponent({ component }) {
  useEffect(() => {
    // document.addEventListener("DOMContentLoaded", function () {
    setTimeout(() => {
      const elements = document.querySelectorAll(
        ".product-card .placeholder-img",
      );
      Array.from(elements).forEach((element) => {
        element.style.display = "block"; // Make the element visible
        const fullHeight = "initial"; // Get the natural height
        console.log("Height: " + fullHeight);
        requestAnimationFrame(() => {
          element.style.height = fullHeight; // Start the slide down effect
        });
        // element.style.height = fullHeight; // Start the slide down effect
      });
      console.log("MyComponent mounted");
    }, 5000);
    // });
    // You can run your JS code here
  }, []); // The empty array ensures this runs once on mount

  return <div>{component}</div>;
}

export default function ShopPage() {
  return (
    <div className="page-container">
      <SiteHeader className="page-header"></SiteHeader>
      <ProductsList />
      <MyComponent />
    </div>
  );
}
