import React from "react";
import MenuItem from "./MenuItem";

Header.defaultProps = {
  logoUrl:
    "https://react.webengine.co.il/wp-content/uploads/2024/03/learn-react-logo.png",
  link1: "/",
  link1Text: "Home",
  link2: "/blog",
  link2Text: "Blog",
  link3: "/shop",
  link3Text: "Shop",
  link4: "/about",
  link4Text: "About Us",
  link5: "/contact",
  link5Text: "Contact Us",
};

export default function Header(props) {
  const {
    logoUrl,
    link1,
    link1Text,
    link2,
    link2Text,
    link3,
    link3Text,
    link4,
    link4Text,
    link5,
    link5Text,
  } = props;
  return (
    <header className="page-header">
      <img className="logo" src={logoUrl} alt="site logo" />
      <nav>
        <MenuItem text={link1Text} link={link1} />
        <MenuItem text={link2Text} link={link2} />
        <MenuItem text={link3Text} link={link3} />
        <MenuItem text={link4Text} link={link4} />
        <MenuItem text={link5Text} link={link5} />
      </nav>
    </header>
  );
}
