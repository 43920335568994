import React from "react";
import PostsList from "../components/PostsList";
import SiteHeader from "../components/header/SiteHeader";
import ImageSlider from "../components/Slider";

export default function HomePage() {
  return (
    <div className="page-container">
      <SiteHeader className="page-header" title="Learn React"></SiteHeader>
      <ImageSlider className="page-slider" />
    </div>
  );
}
