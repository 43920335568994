import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const MenuItem = ({ text, link, options }) => {
  return (
    <Link to={link} {...options}>
      {text}
    </Link>
  );
};

MenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  options: PropTypes.object,
};

MenuItem.defaultProps = {
  options: {},
};

export default MenuItem;
