import React from "react";
import PostsList from "../components/PostsList";
import SiteHeader from "../components/header/SiteHeader";

export default function HomePage() {
  return (
    <div className="page-container">
      <SiteHeader
        className="page-header"
        title="Learn React"
      ></SiteHeader>
      <PostsList />
    </div>
  );
}
