import React from "react";
import { Link } from "react-router-dom";

const formatDate = (date) => new Date(date).toLocaleDateString();

export default function ProductCard({ product }) {
  const { name, date, slug, image, shortDescription } = product;

  return (
    <div className="product-card">
      {image ? (
        <img src={image.sourceUrl} alt={image.caption} />
      ) : (
        <img className="placeholder-img" src={`https://source.unsplash.com/random/800x800?${name}`} />
      )}
      <Link to={`/shop/${slug}`}>
        <h3>{name}</h3>
      </Link>
      <div>{shortDescription.replace(/<\/?p>/g, "")}</div>
      <div className="metadata">
        <span className="text-bold">Published:</span> {date}
        <br />
        <span className="text-bold">Seller:</span> {name}
      </div>
    </div>
  );
}
