import React from "react";
import ProductPageContent from "../components/ProductPageContent";
import { gql, useQuery } from "@apollo/client";
import manageCart from "../components/Cart";
import SiteHeader from "../components/header/SiteHeader";

const GET_PRODUCT_BY_SLUG = gql`
  query getProductBySlug($id: ID!) {
    product(id: $id, idType: SLUG) {
      id: productId
      title
      date
      content
      name
      description
      shortDescription
      productCategories {
        nodes {
          slug
          name
        }
      }
      image {
        sourceUrl
      }
    }
  }
`;
const addToCart = (product, quantity) => {
  manageCart.addToCart(product, quantity);
  console.log(
    `Product added to cart: ${product.name} [${product.id}], Quantity: ${quantity}`,
  );
  // Additional logic for adding the product to the cart can go here
};

export default function ProductPage(props) {
  const { loading, error, data } = useQuery(GET_PRODUCT_BY_SLUG, {
    variables: {
      id: props.match.params.slug,
    },
  });

  const productFound = Boolean(data?.product);

  return (
    <div className="page-container">
      <SiteHeader className="page-header"></SiteHeader>
      {loading ? (
        <p>Loading…</p>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : !productFound ? (
        <p>Product could not be found.</p>
      ) : (
        <ProductPageContent
          product={data.product}
          featuredImageUrl={data.product.image?.sourceUrl}
          addToCart={addToCart}
        />
      )}
    </div>
  );
}
