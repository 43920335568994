import React from "react";
import ProductCard from "../components/ProductCard";
import { gql, useQuery } from "@apollo/client";

const GET_ALL_PRODUCTS = gql`
  query getAllProducts {
    products(first: 80) {
      nodes {
        id
        description
        sku
        slug
        name
        image {
          sourceUrl
          caption
        }
        databaseId
        shortDescription
        metaData {
          key
          value
        }
      }
    }
  }
`;

export default function ProductsList() {
  const { loading, error, data } = useQuery(GET_ALL_PRODUCTS);

  if (loading) return <p>Loading products…</p>;
  if (error) return <p>Error :( </p>;

  const productsFound = Boolean(data?.products.nodes.length);
  if (!productsFound) {
    return <p>No matching products found.</p>;
  }

  return (
    <div className="products-list">
      {data.products.nodes.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
}
