import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client/react";
import client from "./lib/apollo";
import HomePage from "./pages/HomePage";
import ShopPage from "./pages/ShopPage";
import PostPage from "./pages/PostPage";
import BlogPage from "./pages/BlogPage";
import ProductPage from "./pages/ProductPage";
import "./styles.css";

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/shop" component={ShopPage} />
          <Route exact path="/blog" component={BlogPage} />
          <Route path="/blog/:slug" component={PostPage} />
          <Route path="/shop/:slug" component={ProductPage} />
        </Switch>
      </Router>
    </ApolloProvider>
  );
}
