import React from "react";

const QuantityComponent = ({ quantity, setQuantity }) => {
  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  return (
    <div className="quantity-container">
      <button onClick={handleDecrement} className="quantity-btn">
        -
      </button>
      <input type="text" value={quantity} readOnly className="quantity-input" />
      <button onClick={handleIncrement} className="quantity-btn">
        +
      </button>
    </div>
  );
};

export default QuantityComponent;
