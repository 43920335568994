import React from "react";

const manageCart = {
  getCart: () => {
    return JSON.parse(localStorage.getItem('cart')) || [];
  },
  addToCart: (product, quantity) => {
    const cart = manageCart.getCart();
    const existingProductIndex = cart.findIndex(item => item.id === product.id);
    if (existingProductIndex >= 0) {
      cart[existingProductIndex].quantity += quantity;
    } else {
      cart.push({
        id: product.id,
        name: product.name,
        quantity: quantity
      });
    }
    localStorage.setItem('cart', JSON.stringify(cart));
    console.log('Updated Cart:', JSON.stringify(cart, null, 2));
  },
  clearCart: () => {
    localStorage.removeItem('cart');
  }
};

export default manageCart;

