import React, { useState } from "react";
import { Link } from "react-router-dom";
import QuantityComponent from "../components/ProductQuantity";
import ClearCart from "../components/ClearCartBtn";

const formatDate = (date) => new Date(date).toLocaleDateString();

export default function ProductPageContent({
  product,
  featuredImageUrl,
  addToCart,
}) {
  const {
    date,
    title,
    content,
    name,
    productCategories,
    description,
    shortDescription,
  } = product;
  const haveProductCategories = Boolean(productCategories?.nodes?.length);
  const [quantity, setQuantity] = useState(1);

  return (
    <article className="product-page">
      <div className="product-content">
        <div className="product-col-left">
          {featuredImageUrl && (
            <img src={featuredImageUrl} alt={product.title} />
          )}
        </div>
        <div className="product-col-right">
          <h1>{title}</h1>
          <div
            className="product-content"
            dangerouslySetInnerHTML={{ __html: shortDescription }}
          />
          <p className="product-meta">
            <span role="img" aria-label="writing hand">
              ✍️
            </span>{" "}
            {name} on {formatDate(date)}
          </p>
          <QuantityComponent quantity={quantity} setQuantity={setQuantity} />
          <button
            className="add-to-cart-btn"
            onClick={() => addToCart(product, quantity)}
          >
            Add to Cart
          </button>
          <ClearCart />
          {haveProductCategories ? (
            <div className="categories-list">
              <h5>Categorized As</h5>
              <ul>
                {productCategories.nodes.map((category) => {
                  const { slug, name } = category;
                  return (
                    <Link to={`/category/${slug}`} key={slug}>
                      <li key={slug}>{name}</li>
                    </Link>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="product-description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </article>
  );
}
