import React from "react";
import manageCart from "./Cart"; // Adjust the import path based on your file structure

const ClearCart = () => {
  const clearCart = () => {
    manageCart.clearCart();
    console.log("Cart cleared");
  };

  return (
    <button onClick={clearCart} className="clear-cart-btn">
      Clear Cart
    </button>
  );
};

export default ClearCart;
